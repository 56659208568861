
import jobService from "@/services/job.service"
import listsService from "@/services/lists.service"
import ValidationResponse from "@/components/base/api/ValidationResponse"
import Button from "@/components/base/Button"
import Score from "@/components/base/Score"
import Select from "@/components/base/input/Select"

import { formatPrice } from "@/helpers/formatting"
import { routeToReview } from "@/helpers/routing"

export default {
    name: "JobCardAdminOverlay",
    components: { ValidationResponse, Button, Score, Select },
    props: {
        jobSlug: {
            type: String,
            required: true,
        },
    },
    async fetch() {
        this.loading = true
        this.job = await jobService.getJobBySlug(this.jobSlug)
        if (!this.job) return
        if (!this.lists.length) {
            this.lists = await listsService.getJobLists()
        }
        this.boostScore = this.job.boost_score
        this.selectedLists = Array.isArray(this.job.lists)
            ? this.job.lists.map(({ id }) => id)
            : []
        this.loading = false
    },
    data() {
        return {
            routeToReview,
            formatPrice,
            job: null,
            validationResponseData: null,
            boostScore: 0,
            satisfactionEmojis: [
                "😭",
                "😢",
                "☹️",
                "🙁",
                "😐",
                "🙂",
                "😊",
                "😁",
                "😄",
                "😍",
            ],
            lists: [],
            selectedLists: [],
            loading: false,
        }
    },
    computed: {
        score() {
            return Math.round(this.job.quality_score * 100)
        },
    },
    // When entering the review and exiting it again, the component ist kept-alive and the fetch method is not called again.
    // Therefore we need to manually call fetch, once the component is in view again.
    activated() {
        this.$fetch()
    },
    methods: {
        getEmoji(score) {
            return this.satisfactionEmojis[Math.min(Math.floor(score / 10), 9)]
        },
        async updateJob(payload) {
            this.isLoading = true
            try {
                const { data } = await jobService.updateJob(
                    this.job.slug,
                    payload
                )
                this.job = data.data
                this.validationResponseData = null
            } catch (e) {
                this.validationResponseData = e
            } finally {
                this.isLoading = false
            }
        },
        async onListUpdate(lists) {
            await this.updateJob({ lists })
        },
        async onBoostScoreUpdate(score) {
            await this.updateJob({ job: { boost_score: score } })
        },
    },
}
