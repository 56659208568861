
export default {
    name: "Select",
    emits: ["focus", "input"],
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        hasFocus: false,
    }),
    methods: {
        setFocus(value) {
            if (this.readonly) return
            this.hasFocus = value
            this.$emit("focus", value)
            if (!value) {
                this.$refs.select?.blur()
            }
        },
        onInput(input) {
            this.setFocus(false)
            this.$emit("input", input)
        },
    },
}
