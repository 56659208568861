/** Format price in EUR
 * @param {number} number -- number to format
 * @param {boolean} dense -- without space between price number and currency symbol
 * @return {string} formatted price */
export function formatPrice(number, dense = false) {
    var formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
    })
    const formatted = formatter.format(number)
    if (!dense) return formatted
    return formatted.replace(/\s/g, "")
}

export function formatPriceWithoutDecimals(number, dense = false) {
    var formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
    const formatted = formatter.format(number)
    if (!dense) return formatted
    return formatted.replace(/\s/g, "")
}
