
import { jobScoreToColor } from "../../helpers/mapping.js"
export default {
    name: "Score",
    props: {
        value: {
            type: [String, Number],
            default: 0,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        color() {
            return jobScoreToColor(this.value).code
        },
        colorClass() {
            return jobScoreToColor(this.value).color
        },
    },
}
